import { createEffect, createEvent, restore, type EventPayload } from 'effector';
import { type NextRouter } from 'next/router';

type PushParameters = Parameters<NextRouter['push']>;

export type PushPayload = {
	url: PushParameters[0];
	as?: PushParameters[1];
	options?: PushParameters[2];
};

export const push = createEvent<PushPayload>();

export const setRoute = createEvent<string>();
export const $route = restore(setRoute, '');

export const changeLocationFx = createEffect(({ url }: PushPayload) => {
	if (typeof window !== 'undefined') {
		window.location.href = url.toString();
	}
});

export const pushPayload = (url: PushParameters[0], as?: PushParameters[1], options?: PushParameters[2]) =>
	({ url, as, options }) satisfies EventPayload<typeof push>;
